import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  padding: .01rem;
  background-color: #333;
  color: #fff;
  text-align: center;
`;

const Footer = () => (
  <FooterContainer>
    <p>&copy; 2024 DevPlays. All rights reserved.</p>
  </FooterContainer>
);

export default Footer;
