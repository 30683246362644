import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import ContactForm from './components/ContactForm';

const App = () => (
  <div>
    <Routes>
      <Route path="/" element={<HeroSection />} />
      <Route path="/features" element={<Features />} />
      <Route path="/contact" element={<ContactForm />} />
    </Routes>
  </div>
);

export default App;