import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
  background: url('https://img.freepik.com/free-photo/glowing-molecular-structure-futuristic-galaxy-backdrop-generated-by-ai_188544-27827.jpg?w=1060&t=st=1722570845~exp=1722571445~hmac=48fe4243039a3ae4b77c050d610370b1ff39bdb18b21d805fd0a0d6db81ab6f8') no-repeat center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
`;

const HeroSection = () => (
  <HeroContainer>
    <HeroTitle>Welcome to DevPlays</HeroTitle>
    <HeroSubtitle>Your platform for technology and gaming</HeroSubtitle>
  </HeroContainer>
  
);

export default HeroSection;
