import React from 'react';
import styled from 'styled-components';

const FeaturesContainer = styled.div`
  padding: 4rem 2rem;
  text-align: center;
`;

const Feature = styled.div`
  margin: 2rem 0;
`;

const FeaturesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
`;

const FeaturesList = () => (
  <FeaturesContainer>
    <FeaturesTitle>Features</FeaturesTitle>
    <Feature>
      <h3>Feature 1</h3>
      <p>Description of Feature 1</p>
    </Feature>
    <Feature>
      <h3>Feature 2</h3>
      <p>Description of Feature 2</p>
    </Feature>
    <Feature>
      <h3>Feature 3</h3>
      <p>Description of Feature 3</p>
    </Feature>
  </FeaturesContainer>
);

export default FeaturesList;
